<script lang="ts">
	import { KnownImages } from "../helpers/KnownImages";
	export let title: string | undefined = undefined;
	export let description: string | undefined = undefined;
	export let imageUrl: string | undefined = KnownImages.LOGO_FULL_COLOR_TRANSPARENT;

	let completeTitle = "";
	let completeDescription = "";
	$: {
		completeTitle = title ? `${title} | Dein-Ticket.Shop` : "Dein-Ticket.Shop";
		completeDescription = description ? description : "Veranstaltungstickets kaufen";
	}
</script>

<svelte:head>
	<title>{completeTitle}</title>
	<meta name="description" content={completeDescription} />
	<meta property="og:title" content={completeTitle} />
	<meta property="og:description" content={completeDescription} />
	{#if imageUrl}<meta property="og:image" content={imageUrl} />{/if}
	<meta property="og:type" content="website" />
	<meta property="og:site_name" content="Dein-Ticket.Shop" />
	<meta property="og:locale" content="de_DE" />
</svelte:head>
